export const colors = {
  black: '#232627',
  white: '#FFFFFF',
  beige: '#F9F6ED',
  gray: '#B8B8B9',
  lightGray: '#f8f8f8',
  darkGray: '#9d9fa2',
  darkerGray: '#636466',
  green: '#96AD41',
  lightGreen: '#a7c57a',
  blue: '#94BFD5',
  darkBlue: '#163C58',
  red: '#BA494F',
  text: '#232627',
  orange: '#D57824',
  transparent: 'rgba(0, 0, 0, 0)',
  blackOverlay: 'rgba(0, 0, 0, .6)',
  facebook: '#4267b2',
  youtube: '#f00f00',
  pinterest: '#e60023',
  instagram: '#d30066',
};

export const font = {
  primary: 'Rokkitt, serif',
  secondary: '"Gotham A", "Gotham B", serif',
};

export const spacing = {
  headerHeight: '116.66px',
  headerMobile: '96.66px',
  headerAlert: '143.66px',
};

export const breakpoints = {
  mobile: 480,
  ipadLand: 1024,
  headerBreak: 1140,
  ipadMid: 960,
  midTab: 896,
  ipadPort: 768,
  pageWidth: 1200,
  mapBreak1: 1660,
};

export const misc = {
  sectionMargin: 100,
  sectionMobileMargin: 50,
  animSpeed: '.3s',
};
